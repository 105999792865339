import React, { useState, useEffect } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import ModalTiny from "react-bootstrap/Modal"
import { Link } from "@StarberryUtils"
import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";
// import MassApply from "@Components/MassApply"
import $ from "jquery"
import { numberWithCommas } from "@Components/common/site/common-functions"
import { JOBS_PAGE_URL, JOBSA_PAGE_URL, JOBSD_PAGE_URL } from "@Components/common/site/constants";
import {
  getCookie,
  saveCookie,
  isStoredInCookie,
} from "@Components/myaccount/utils/cookie"
import axios from "axios"
import { SaveItem } from "@Components/myaccount/wishlistlink"
import ScrollAnimation from 'react-animate-on-scroll'
import SEO from "@Components/seo";
// Capitalize
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const no_image = "/images/no-image.jpg"

const PropertySection = ( props ) => {
  // Wishlist related changes - satrt
  const [wishlist, setWishlist] = useState(false)
  const [wishlistItems, setWishlistItems] = useState(false)
  const [isLoading, setisLoading] = useState(true)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedItemsTitles, setSelectedItemsTitles] = useState([])
  const [modalSendfrndformOpen, setSendfrndformOpen] = useState(false)
  const [modalNegoformOpen, setNegoformOpen] = useState(false)

  const openSendfrndformModal = () => {
    setSendfrndformOpen(true)
  }
  const closeSendfrndformModal = () => {
    setSendfrndformOpen(false)
  }

  const openNegoformModal = () => {
    setNegoformOpen(true)
  }
  const closeNegoformModal = () => {
    setNegoformOpen(false)
  }

  const checkSave = pid => {
    let data = isStoredInCookie("favourites", pid)
    if (data) $("html").addClass("has-wishlist")

    return data
  }

  const getSelecteditems = () => {

    let checkedItemsValues = []
    let checkedItemstitles = []
    let checkedItemstitlesid = []
    let checkedItems = $(".check-card input:checked")
    if (checkedItems.length) {
      checkedItems.map((index, item) => {
        var spliditem = $(item).val().split('+++')
        checkedItemsValues.push(spliditem[0])
        checkedItemstitles.push(spliditem[1])
        checkedItemstitlesid.push($(item).val())
      })
    }


    if(checkedItems.length == 0)
    {
      $(".section-news-cards input").prop("checked", false)
      setSelectAll(false)
    }

    setSelectedItems(checkedItemsValues)
    setSelectedItemsTitles(checkedItemstitles)
    sessionStorage.setItem('checkedItemsValues', checkedItemsValues.toString())
    sessionStorage.setItem('checkedItemstitles', checkedItemstitles.toString())
    sessionStorage.setItem('checkedItemstitlesid', checkedItemstitlesid.toString())
    if ($(".check-card input").length == checkedItemsValues.length) {
      setSelectAll(true)
    }

  }

  const handleSingleSelect = () => {
    getSelecteditems()
  }

  const handleSelectAll = () => {
    if (selectAll) {
      $(".section-news-cards input").prop("checked", false)
      setSelectAll(false)
    } else {
      $(".section-news-cards input").prop("checked", true)
      setSelectAll(true)
    }
    getSelecteditems()
  }

  const handleSave = (event, pid) => {
    event.preventDefault()
    event.stopPropagation()

    let response = saveCookie("favourites", pid)
    if (response.count) {
      setWishlist(response.count)
      $("html").addClass("has-wishlist")
    } else {
      setWishlist(0)
      $("html").removeClass("has-wishlist")
    }
    $(".wishlist-count").text(response.count)
  }

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  })// could be from env files
      setWishlistItems(data)
      setisLoading(false)
      getSelecteditems()
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }

  // we could avoid to re call to server, we have latest data in cookie and read it, when rendering we could check with cookie id and item id
  useEffect(() => {
    // const page = 1
    let wishlistIds = getCookie("favourites")
    if (wishlistIds.length) {
      let queryString = wishlistIds.map(i => "id_in=" + i).join("&")
      let url =
        process.env.GATSBY_STRAPI_SRC+'/jobs?' + queryString
      getitems(url)
    } else {
      setisLoading(false)
      setWishlistItems([])
    }
  }, [wishlist])

  // Wishlist related changes - end

  return (
    <>
                <SEO title={"Job Wishlist"} description={"Use the Wishlist as a quick and easy way to apply your favorite jobs at Allsopp & Allsopp Recruitment.."} />
      <section className="body-content section-white header-content">
        <Container>
          <Row>
            {/* <Col lg={12}>
              <h1>Job Wishlist {!wishlistItems.length ? ('') : (`(${wishlistItems.length})`)}</h1>
            </Col> */}
            <Col xl={8} lg={12}>
            <h1>Job Wishlist {!wishlistItems.length ? ('') : (`(${wishlistItems.length})`)}</h1>
              <p className="content_r-l">
                Use the wishlist as a quick and easy way to apply your favourite
                jobs.<br/>
                Click the “Select All” button if you wish to apply to all your shortlisted jobs<br/>
                If you would like to remove the jobs from your wishlist, please click the “Shortlisted” button from the individual jobs below.</p>
            </Col>
            <Col xl={4} lg={12} className="d-flex-whitelist">
              <ul className="list-inline text-xl-right property-wishlist">
                {!wishlistItems.length ? (
                  ""
                ) : (
                  <>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="btn min-width-btn-fix btn--secondary-outline width-200 text-uppercase"
                        onClick={handleSelectAll}
                      >
                        {wishlistItems.length === 1 && selectAll ? "Unselect" : wishlistItems.length === 1 && !selectAll ? "select" : selectAll ? "Unselect All" : "Select All"}
                      </Link>
                    </li>

                    {!selectedItems.length ? (
                      ""
                    ) : (
                      <>
                        {/* <li className="list-inline-item">
                          <Link
                            to="#"
                            className="btn btn--secondary width-200 text-uppercase"
                            onClick={openSendfrndformModal}
                          >
                            Share
                          </Link>
                        </li> */}
                        <li className="list-inline-item ml-md-2">
                          <Link
                            to="/for-candidates/job-apply/all"
                            className="btn min-width-btn-fix btn--secondary width-200 text-uppercase"
                            // onClick={openNegoformModal}
                          >
                            Apply 
                          </Link>
                        </li>
                      </>
                    )}
                  </>
                )}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-white section-news-cards">
        {/* {JSON.stringify(selectedItems)}
        {JSON.stringify(selectedItemsTitles)} */}
        
        <Container>
          <Row>
            {!wishlistItems.length && !isLoading && (
              <Col lg={12}>
                <p>
                You don't have any jobs on your wishlist yet. <Link className="link-go-search" to="/for-candidates/job-search">Click here</Link> to start your job search now. When you find something you like, click the 'heart' icon.
                </p>
              </Col>
            )}
{/* {JSON.stringify(wishlistItems)} */}
<Col lg={12}>
            {wishlistItems &&
              wishlistItems.map((hit, i) => {
                let link = JOBSD_PAGE_URL.alias+"/"+hit.slug+'-'+hit._id
                return (
                  // <Link to={JOBSD_PAGE_URL.alias+"/"+hit.slug+'-'+hit._id}>
                  <div className="job">
                <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                  {/* {JSON.stringify(hit)} */}
                <div className="wishlist-check">
                            <label className="check-card mb-4 whitelist-check">
                              <input
                                type="checkbox"
                                name={`property-${hit.jobopeningid}`}
                                value={`${hit.jobopeningid}+++${hit.posting_title}`}
                                onClick={handleSingleSelect}
                              />
                              <span className="check-mark"></span>
                            </label>
                            <h2><Link to={JOBSD_PAGE_URL.alias+"/"+hit.slug+'-'+hit._id}>{hit.posting_title}</Link></h2>
                          </div>
                          <div className="job-nito">
                    {hit.city && hit.state &&
                    <div className="job-info">
                            <i className="icon-location"> </i>
                        <span>{capitalize(hit.city.replace(/-/g," "))}{hit.city ? ', ' : ''} {hit.state}</span>
                    </div>
                    }
                    {hit.job_type &&
                    <div className="job-info">
                            <i className="icon-clock">
                            </i>
                        <span>{capitalize(hit.job_type.replace(/-/g," "))}</span>
                    </div>
                    }
                    {hit.salary && !hit.salaryNAN &&
                    <div className="job-info">
                            <i className="icon-money">
                            </i>
                        <span>{numberWithCommas(hit.salary)} {hit.currency}</span>
                    </div>
                    }
                    {hit.salaryNAN &&
                    <div className="job-info">
                            <i className="icon-money">
                            </i>
                        <span>{hit.salaryNAN}</span>
                    </div>
                    }
                    </div>
                    {/* <ReactMarkdown source={hit.job_description} allowDangerousHtml /> */}
                    <p className="desc-para">
                      <span dangerouslySetInnerHTML={{ __html: hit.job_description?.replace(/<[^>]+>/g, '').substring(0, 150)}}></span>{hit.job_description?.length > 300 ? <>...
                      <Link to={JOBSD_PAGE_URL.alias+"/"+hit.slug+'-'+hit._id}  className="read-more-btn-job">
                            Read More
                        </Link>
                      </>
: ''}
                      </p>
                    <div className="buttons">
                       {/* <ApplyModal job_url={process.env.GATSBY_SITE_URL+"jobs/detail/"+hit._id} posting_title={hit.posting_title} /> */}
                       <Link to={JOBSD_PAGE_URL.alias+"/"+hit.slug+'-'+hit._id}  className="btn more-info-btn">
                            <i className="icon-cross icon-info-job"> </i>
                            More Info
                        </Link>
                        <Link to={JOBSA_PAGE_URL.alias+"/"+hit.slug+'-'+hit._id}  className="btn">
                            <i className="icon-cross icon-cross-job"> </i>
                            quick apply
                        </Link>
                        {/* <a href="#" className="like-us">
                            <i className="icon-like">
                            </i>
                            <span className="sm-text d-none d-md-block">
                                shortlist
                         </span>
                        </a> */}
                        <Link
      className={checkSave(hit._id) ? `like-us saved` : "like-us"}
      to="#"
      onClick={event => handleSave(event, hit._id)}
    >
      <i className="icon-like">
                            </i>
                            {checkSave(hit._id) ?
                            <span className="sm-text d-none d-md-block">
                                shortlisted
                         </span>
                         : <span className="sm-text d-none d-md-block">
                         shortlist
                  </span>}
    </Link>
                        {/* <SaveItem itemId={hit._id} itemClass="like-us" /> */}
                    </div>
                </ScrollAnimation>
                </div>
                // </Link>
                )
              })}
              </Col>
          </Row>
        </Container>
      </section>
      <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="property-contact-modal">
        <ModalTiny.Header closeButton className="contact-close-btn">
          {/* <ModalTiny.Title>Share to a Friends</ModalTiny.Title> */}
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Share to a Friend</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body>
          {/* <SendfrndEmailForm properties={selectedItems} /> */}
        </ModalTiny.Body>
      </ModalTiny>
      <ModalTiny
        show={modalNegoformOpen}
        backdrop="static"
        onHide={closeNegoformModal}
        className="property-contact-modal"
      >
        <ModalTiny.Header closeButton className="contact-close-btn">
          <ModalTiny.Title className="w-100">
            {/* <h2 className="text-center mb-0 text-uppercase">Contact Agent</h2> */}
          </ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body>
          {/* <MassApply selectedItems={selectedItems} selectedItemsTitles={selectedItemsTitles} /> */}
        </ModalTiny.Body>
      </ModalTiny>
    </>
  )
}

class PropertyWishlistPage extends React.Component {
  constructor(props){
		super(props)
  }

  componentDidMount(){
    // Page loader
		window.pageExitTime = 1000
  }

  render() {
    return (
      <>
        {/* <section className={"loader-wrapper " + this.props.status}>
            <div id="loader-wrapper">
              <div id="loader">
                <div className="loader-bg"></div>
                <div className="loader-logo"></div>
              </div>
              <div class="loader-section section-left"></div>
              <div class="loader-section section-right"></div>
            </div>
			  </section> */}

        {/* <Header /> */}
        <Header 
    // contact={contact}
     nobanner={true} 
    // siteTitle={data.site.siteMetadata?.title || `Title`}
     />

        <PropertySection location={this.props.location} />
        <Footer />
       
      </>
    )
  }
}

export default PropertyWishlistPage
